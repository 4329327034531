export const colors = {
  lightBlue: '#EFFAFF',
  blue: '#54B5EE',
  bgBlue: '#5fbdf4',
  bgBlue2: '#6EC7F5',
  lightDarkBlue: '#2196f3',
  darkBlue: '#1976D2',
  green: '#6FD43D',
  red: '#D25656',
  orange: '#F98958',
  grey: '#707070',
  gold: '#FFD000',
  silver: '#B9B9B9',
  bronze: '#DF882B',
  transparent: 'transparent',
};
